// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Hero from "../components/cookie/hero"
import PageContent from "../components/cookie/content"

const Cookie = ({ data, pageContext }) => {
  const { allContentfulFooter } = data
  const langKey = pageContext.langKey

  return (
    <Layout
      isDarkMode
      langKey={langKey}
      allContentfulFooter={allContentfulFooter}
    >
      <Helmet>
        <script src="https://webforms.pipedrive.com/f/loader"></script>
        <title> Cookies Policy </title>
        <meta name="description" content="Cookies Policy" />
        <meta name="format-detection" content="telephone=no" />
        {langKey === "en" && <link rel="alternate" hreflang="en-us" />}
        {langKey === "fr" && <link rel="alternate" hreflang="fr" />}
      </Helmet>
      <Hero />
      <PageContent />
    </Layout>
  )
}

export default Cookie

export const data = graphql`
  query CookiesPolicyQuery($langKey: String!) {
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
