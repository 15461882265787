// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../layout/container"
import Content from "../../content/cookie/cookie.json"

import Paragraph from "./paragraph"
import LastParagraph from "./last-paragraph"

const {
  title1,
  text1,
  title2,
  text2,
  title3,
  text3,
  title4,
  text4,
  title5,
  text5,
  title6,
  text6,
  title7,
  text7,
  title8,
  text8,
} = Content.fr.section

const PageContent = () => {
  return (
    <Container overrideCSS={{ marginTop: `5rem` }}>
      <Paragraph title={title1} text={text1} />
      <Paragraph title={title2} text={text2} />
      <Paragraph title={title3} text={text3} />
      <Paragraph title={title4} text={text4} />
      <Paragraph title={title5} text={text5} />
      <Paragraph title={title6} text={text6} />
      <Paragraph title={title7} text={text7} />
      <Paragraph title={title8} text={text8} />
      <LastParagraph />
    </Container>
  )
}

export default PageContent
