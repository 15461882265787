// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Content from "../../content/cookie/cookie.json"

const {
  title,
  text1,
  email,
  phone,
  emailText,
  phoneText,
} = Content.fr.lastParagraph
const LastParagraph = () => {
  return (
    <div sx={{ width: `70%`, margin: `auto`, marginBottom: `5rem` }}>
      <h2>{title}</h2>
      <p>{text1}</p>
      <div sx={{ display: `flex` }}>
        <p>{email}</p> &nbsp;
        <p>{emailText}</p>
      </div>
      <div sx={{ display: `flex` }}>
        <p>{phone}</p> &nbsp;
        <p>{phoneText}</p>
      </div>
    </div>
  )
}

export default LastParagraph
