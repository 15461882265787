// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const Paragraph = ({ title, text }) => {
  return (
    <div sx={{ width: `70%`, margin: `auto`, py: `2rem` }}>
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  )
}

export default Paragraph
