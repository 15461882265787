// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "../layout/container"

import { colors } from "../../utils/theme/colors"
import Content from "../../content/cookie/cookie.json"

const title = Content.en.title

const Hero = () => {
  return (
    <div
      sx={{
        backgroundColor: colors.code.secondary,
      }}
    >
      <Container>
        <div sx={{ height: `30rem`, display: `flex` }}>
          <h1 sx={{ color: "#ffffff", margin: `auto` }}>{title}</h1>
        </div>
      </Container>
    </div>
  )
}

export default Hero
